<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800px">
    <template #activator="{ on: dialog }">
      <v-tooltip top>
        <template #activator="{ on: tooltip }">
          <v-btn
            text
            color="warning"
            @click="onShowDialog"
            v-on="{ ...tooltip, ...dialog }"
            data-test="activateButton"
          >
            Create TPCB Config
          </v-btn>
        </template>
        <span>Add TPCB Config</span>
      </v-tooltip>
    </template>
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark>
          <v-toolbar-title data-test="projectName">Add TPCB Config - Project: {{ projectName }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="closeButton">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" md="9">
            <v-text-field
              dense
              label="Name"
              name="name"
              type="text"
              autocomplete="off"
              v-model="tpcbName"
              data-test="tpcbName"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-btn block :disabled="!tpcbName" color="secondary" @click="saveHandler" data-test="saveButton"
              >Save TPCB Config</v-btn
            >
          </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "TpcbConfigModal",
  props: {
    projectName: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      dialog: false,
      tpcbName: null,
    };
  },
  methods: {
    onShowDialog() {
      this.tpcbName = null;
    },
    saveHandler() {
      this.$emit("saveData", this.tpcbName);
      this.onClose();
    },
    onClose() {
      this.dialog = false;
    }
  }
};
</script>
